<template>
  <div class="main">
    <div class="navbar">
      <a href="https://tupibox.com/" target="_blank"
        ><img src="@/assets/logo.png" alt="logo"
      /></a>
    </div>
    <progress-bar :activeField="activeStep" :formLength="10"></progress-bar>
    <section class="section" style="padding-top: 0.5rem" v-if="configs">
      <b-steps v-model="activeStep" :animated="true" :has-navigation="false">
        <b-step-item>
          <step-zero></step-zero>
        </b-step-item>
        <b-step-item>
          <step-one></step-one>
        </b-step-item>
        <b-step-item>
          <step-two></step-two>
        </b-step-item>
        <b-step-item>
          <step-three></step-three>
        </b-step-item>
        <b-step-item>
          <step-four></step-four>
        </b-step-item>
        <b-step-item>
          <step-five></step-five>
        </b-step-item>
        <!-- <b-step-item>
              <step-select-box-size></step-select-box-size>
          </b-step-item> -->
        <b-step-item>
          <step-six></step-six>
        </b-step-item>
        <b-step-item>
          <extra-toy></extra-toy>
        </b-step-item>
        <b-step-item>
          <step-seven></step-seven>
        </b-step-item>
        <b-step-item>
          <step-checkout></step-checkout>
        </b-step-item>
      </b-steps>
    </section>
    <div class="loading" v-else>
      <loader></loader>
    </div>
    <div class="footerSteps">2024 TupiBox Todos los derechos reservados</div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "./Loader.vue";
import StepSelectBoxSize from "./steps/StepSelectBoxSize.vue";
import ProgressBar from "./ProgressBar.vue";
import StepZero from "./steps/StepZero.vue";
import StepOne from "./steps/StepOne.vue";
import StepTwo from "./steps/StepTwo.vue";
import StepThree from "./steps/StepThree.vue";
import StepFour from "./steps/StepFour.vue";
import StepFive from "./steps/StepFive.vue";
import StepSix from "./steps/StepSix.vue";
import StepSeven from "./steps/StepSeven.vue";
import StepCheckout from "./steps/StepCheckout.vue";
import ExtraToy from "./steps/ExtraToy.vue";

export default {
  name: "Steps",
  components: {
    StepZero,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    StepSix,
    StepSeven,
    StepCheckout,
    ExtraToy,
    ProgressBar,
    StepSelectBoxSize,
    Loader,
  },
  data() {
    return {
      activeStep: 0,
      cuponEventAdded: false,
      formData: {
        allergie: "",
      },
      configs: null,
    };
  },
  computed: {},
  methods: {
    nextStep(payload) {
      function addEvent(obj, evt, fn) {
          if (obj.addEventListener) {
              obj.addEventListener(evt, fn, false);
          } else if (obj.attachEvent) {
              obj.attachEvent("on" + evt, fn);
          }
      }
      function removeEvent(obj, evt, fn) {
          if (obj.addEventListener) {
              obj.removeEventListener(evt, fn, false);
          }
      }
      function evnt(evt) {
          if (evt.toElement == null && evt.relatedTarget == null) {
            console.log(this.activeStep);
            exitPopup.classList.add('show');
            removeEvent(document, 'mouseout', evnt);
          }
      }
      this.activeStep = this.activeStep + 1;
      if (this.activeStep == 5) {
        if (this.formData.product_type == "hygiene") {
          this.activeStep = this.activeStep + 1;
        }
      }
      if (this.activeStep == 9 && this.cuponEventAdded == false) {
        addEvent(document, 'mouseout', evnt);
        this.cuponEventAdded = true;
      }
    },
    backStep(payload) {
      this.activeStep = this.activeStep - 1;
      if (this.activeStep == 5) {
        if (this.formData.product_type == "hygiene") {
          this.activeStep = this.activeStep - 1;
        }
      }
    },
  },
  async mounted() {
    try {
      let { data: configs } = await axios.get(
        "https://hook.us1.make.com/poeujn6gbch85g09g72m3wk8qhrjo182",
      );
      this.configs = configs;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<template>
  <div class="stepSuccess">
      <div class="form" v-if="result === 'completed'">
          <div class="f-modal-icon f-modal-success animate">
              <span class="f-modal-line f-modal-tip animateSuccessTip"></span>
              <span class="f-modal-line f-modal-long animateSuccessLong"></span>
              <div class="f-modal-placeholder"></div>
              <div class="f-modal-fix"></div>
          </div>
          <div class="titleStep">
              <h1>El pago se ha procesado con Exito</h1>
              <p>Muchas gracias por unirte a TupiBox</p>
          </div>
          <b-button type="is-primary nextButton" @click="goHome">Volver al Home</b-button>
      </div>
      <div class="form" v-if="result === 'failed'">
            <div class="f-modal-icon f-modal-error animate">
                <span class="f-modal-x-mark">
                    <span class="f-modal-line f-modal-left animateXLeft"></span>
                    <span class="f-modal-line f-modal-right animateXRight"></span>
                </span>
                <div class="f-modal-placeholder"></div>
                <div class="f-modal-fix"></div>
            </div>
          <div class="titleStep">
              <h1>¡Ups! Algo ha salido mal</h1>
              <p>No hemos podido procesar tu pago. Por favor verifica que todo está correcto. Si el problema persiste, ponte en contacto con nosotros a contacto@tupibox.com</p>
          </div>
          <b-button type="is-primary nextButton" @click="goHome">Volver al Home</b-button>
      </div>
      <div class="form" v-if="result === 'pending'">
            <div class="f-modal-icon f-modal-warning scaleWarning">
                <span class="f-modal-body pulseWarningIns"></span>
                <span class="f-modal-dot pulseWarningIns"></span>
            </div>
          <div class="titleStep">
              <h1>Pago Pendiente...</h1>
              <!-- <p>Answer some basic questions about your dog and we’ll get the box that’s just right for them.</p> -->
          </div>
          <b-button type="is-primary nextButton" @click="goHome">Volver al Home</b-button>
      </div>
  </div>
</template>
<script>
    export default {
        data() {
            return {
              result: null
            }
        },
        methods: {
            goHome(){
                window.location = 'http://www.tupibox.com'
            }
        },
        async mounted(){
          let params = this.$route.query

          //this.result = params.x_result
          this.result = params.status

          /* update webhook */
          let req = await this.axios({
              method: 'post',
              url: 'https://hook.us1.make.com/koycg0xsqb1dhod5hwpykp4w2yhyr1n5',
              data: params
          })
          
        }
    }
</script>
